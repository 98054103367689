* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --body-background: #fdc3b5;
  /* --calculator-screen: #ffffff; */
  --text-color: #ffffff;
  --calculator-background: linear-gradient(43deg, #c850c0 46%, #4158d0 100%);

  /* themes */
  --theme-1: linear-gradient(43deg, #0093e9 0%, #80d0c7 100%);
  --theme-2: linear-gradient(43deg, #ff9a8b 0%, #ff6a88 55%);
  --theme-3: linear-gradient(43deg, #c850c0 46%, #4158d0 100%);
}

.body-container {
  font-family: Arial, Helvetica, sans-serif;
  height: 100svh;
  background-color: var(--body-background);
}

.calculator-container {
  width: 100%;
  height: 100%;
  background-image: var(--calculator-background);
  transition: background-image 1s;
}

.calculator-screen {
  background-color: var(--calculator-screen);
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.5em;
}

.calculator-buttons {
  height: 70%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
  gap: 5px;
}

.button {
  background-color: magenta;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-size: 1.5em;
  /* glass effect */
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  cursor: pointer;
}

.zero {
  grid-column: 1/3;
}

.equation,
.current-value {
  color: var(--text-color);
  font-weight: bold;
}

.equation {
  font-size: 1.5em;
  opacity: 0.5;
}

.current-value {
  font-size: 4em;
}

.theme-picker-container {
  position: absolute;
  top: 1em;
  left: 1em;
  width: 2em;
  height: 2em;
  background-image: var(--calculator-background);
  border-radius: 2em;
  border: 1px solid white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.theme-wrapper {
  position: relative;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-container {
  position: absolute;
  top: 2.5em;
}

.theme {
  width: 2em;
  height: 2em;
  border-radius: 2em;
  border: 1px solid white;
  margin: 0.2em 0;
  cursor: pointer;
}

.theme-1 {
  background-image: var(--theme-1);
}

.theme-2 {
  background-image: var(--theme-2);
}

.theme-3 {
  background-image: var(--theme-3);
}

.hide {
  display: none;
}

@media all and (min-width: 1199px) {
  .body-container {
    font-size: 25px;
  }
}
